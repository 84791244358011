<template>
  <div v-frag>
    <!-- MENU TOOLBAR START -->
    <v-app-bar color="indigo darken-2" dark tile>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on">
            <v-icon @click="$router.push({name: 'menuBuilder-home'})">
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('backToAllMenus') }}</span>
      </v-tooltip>
      <v-toolbar-title>
        <b>{{ settings.translation.name }}</b> - szerkesztése
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on" @click="popupSettings.isVisible = true;">
            <v-icon>
              mdi-cog
            </v-icon>
          </v-btn>
        </template>
        <span>Menü beállításai</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn v-show="status === 'NEW'" icon v-on="on" @click="createMenu">
            <v-icon>
              mdi-cloud-upload
            </v-icon>
          </v-btn>
        </template>
        <span>Menü mentése</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on" v-show="status === 'PUBLISHED'" @click="updateMenu">
            <v-icon>
              mdi-cloud-refresh
            </v-icon>
          </v-btn>
        </template>
        <span>Frissítés</span>
      </v-tooltip>
    </v-app-bar>
    <!-- MENU TOOLBAR END -->
    <!-- CONTENT START -->
    <v-container fluid>
      <v-row>
        <!-- ELÉRHETŐ OLDALAK START -->
        <v-col cols="12" sm="5" md="4">
          <v-card class="mb-4">
            <v-card-title>
              {{ $t('availablePages') }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 600px; overflow-y: auto;">
              <v-list>
                <template v-for="(item, index) in availablePages">
                  <v-list-item :key="index">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.url }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon @click="addItemToMenu(item)">
                        mdi-plus
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="`div-${index}`"></v-divider>
                </template>
              </v-list>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-title>
              {{ $t('customLink') }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-text-field v-model="customLink.name"
                            :label="$t('labels.navigationLabel')"
                            :hint="$t('hints.requiredField')"
                            persistent-hint
                            filled
                            dense>
              </v-text-field>
              <v-text-field v-model="customLink.url"
                            :label="$t('labels.href')"
                            filled
                            dense>
              </v-text-field>
            </v-card-text>
            <v-card-actions class="px-4 pb-4">
              <v-spacer></v-spacer>
              <v-btn color="primary"
                     @click="addItemToMenu(customLink, customLinkType)"
                     :disabled="customLink.name.length < 1">
                {{ $t('button.add') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- ELÉRHETŐ OLDALAK END -->
        <!-- MENÜ STRUKTÚRA START -->
        <v-col cols="12" sm="7" md="8">
          <v-card>
            <v-card-title>
              {{ $t('menuStructure') }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <nested-draggable :items="menu"
                                :depth="1"
                                v-on:parent-method="showItem"
                                v-on:delete-method="deleteMenuItem">
              </nested-draggable>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- MENÜ STRUKTÚRA END -->
      </v-row>
    </v-container>
    <!-- CONTENT END -->
    <!-- MENU SETTINGS POPUP START -->
    <v-dialog v-model="popupSettings.isVisible" scrollable persistent transition="dialog-bottom-transition" max-width="640">
      <v-card tile>
        <v-card-title class="indigo">
            <span class="white--text">
              {{ $t('menuSettings') }}
            </span>
          <v-spacer></v-spacer>
          <v-icon @click="popupSettings.isVisible = !popupSettings.isVisible" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-form v-model="settingsForm">
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="settings.translation.name"
                              :label="$t('labels.title')"
                              :rules="[$store.state.inputRules.requiredField]"
                              :hint="$t('hints.requiredField')"
                              filled
                              persistent-hint>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="settings.isDefault"
                          :items="selectFieldOptions.isDefault"
                          :label="$t('labels.isDefaultMenu')"
                          filled>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-4 px-5">
          <v-spacer></v-spacer>
          <v-btn text @click="popupSettings.isVisible = false" class="mr-4">
            Mégsem
          </v-btn>
          <v-btn color="primary" @click="popupSettings.isVisible = false" :disabled="!settingsForm">
            Mentés
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MENU SETTINGS POPUP END -->
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussCickInLARDialog">
    </loading-and-response-dialog>
    <!-- LAR DIALOG END -->
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import NestedDraggable from '@/components/shared/NestedDraggable.vue';
import LoadingAndResponseDialog from '@/components/shared/LoadingAndResponseDialog.vue';

export default {
  name: 'menuBuilderEditor',
  components: {
    draggable, NestedDraggable, LoadingAndResponseDialog,
  },
  props: {
    //
  },
  async beforeMount() {
    this.availablePages = await this.getAvailablePages();
    if (this.$route.params.menuId === 'uj-menu') {
      this.status = 'NEW';
      this.popupSettings.isVisible = true;
    } else {
      this.status = 'PUBLISHED';
      const loadIsSuccess = await this.loadMenu(this.$route.params.menuId);
      if (loadIsSuccess) {
        await this.convertJsonToObject(this.settings.contentJson);
      }
    }
  },
  data() {
    return {
      menu: [],
      settings: {
        id: null,
        isDefault: 0,
        contentJson: [],
        modifiedBy: 1,
        translation: {
          id: null,
          language: 'hu',
          name: 'Új menü',
        },
      },
      availablePages: [],
      status: 'NEW',
      popupSettings: {
        isVisible: false,
      },
      settingsForm: false,
      selectFieldOptions: {
        isDefault: [
          { text: 'Igen', value: 1 },
          { text: 'Nem', value: 0 },
        ],
      },
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'LOADING_MESSAGES.PROCESS_IN_PROGRESS',
        successText: 'SUCCESS_MESSAGES.MENU_CREATED',
        errorText: 'ERROR_MESSAGES.MENU_CREATED',
        successBtnText: 'button.ok',
        errorBtnText: 'button.ok',
      },
      customLink: {
        name: '',
        url: '',
      },
      customLinkType: this.$navigationLinkCustom,
    };
  },
  methods: {
    async getAvailablePages() {
      if (this.$store.state.general.availablePages.length < 1) {
        const response = await this.$store.dispatch('showPages');
        if (response.status === 200) {
          const temp = [];
          response.data.forEach((item) => {
            if (!item.isDraft) {
              temp.push(
                {
                  name: `${item.webPageTranslation[0].name}`,
                  url: `/${item.webPageTranslation[0].url}`,
                  group: 'Oldal',
                },
              );
            }
          });
          this.$store.commit('setAllAvailablePages', temp);
        }
      }
      return this.$store.state.general.availablePages;
    },
    async loadMenu(id) {
      const response = await this.$store.dispatch('showMenuById', id);
      if (response.status === 200) {
        this.settings = response.data;
        return true;
      }
      return false;
    },
    async convertJsonToObject(jsonString) {
      this.menu = JSON.parse(jsonString);
    },
    async createMenu() {
      this.openLARDialog();
      const menuToConvert = [];
      this.menu.forEach((item) => {
        menuToConvert.push(item);
      });
      this.settings.contentJson = JSON.stringify(menuToConvert);
      const response = await this.$store.dispatch('createMenu', this.settings);
      if (response.status === 200 || response.status === 201) {
        this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.MENU_CREATED';
        setTimeout(this.changeLARDialogContentToSuccess, 1100);
      } else {
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.MENU_CREATED';
        setTimeout(this.changeLARDialogContentToError, 1100);
      }
    },
    async updateMenu() {
      this.openLARDialog();
      const menuToConvert = [];
      this.menu.forEach((item) => {
        menuToConvert.push(item);
      });
      this.settings.contentJson = JSON.stringify(menuToConvert);
      this.settings.modifiedBy = 1;
      const response = await this.$store.dispatch('updateMenu', this.settings);
      if (response.status === 200 || response.status === 201) {
        this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.MENU_UPDATED';
        setTimeout(this.changeLARDialogContentToSuccess, 1100);
      } else {
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.MENU_UPDATED';
        setTimeout(this.changeLARDialogContentToError, 1100);
      }
    },
    addItemToMenu(item, linkType = this.$navigationLinkNormal) {
      const id = `menu-${Date.now()}`;
      // console.log('id', id);
      this.menu.push(
        {
          text: item.name,
          label: item.name,
          icon: 'mdi-home',
          url: item.url,
          type: linkType,
          menuId: id,
          children: [],
          panel: null,
        },
      );
      if (linkType === this.$navigationLinkCustom) {
        this.customLink = {
          name: '',
          url: '',
        };
      }
    },
    async findMenuItem(item) {
      let l1 = -1;
      let l2 = -1;
      let l3 = -1;
      // 1. szinten keresek
      let selectedItem = this.menu.find((element) => element.menuId === item.menuId);
      if (selectedItem) {
        l1 = this.menu.findIndex((element) => element.menuId === item.menuId);
      } else {
        for (l1 = 0; l1 < this.menu.length; l1++) {
          // 2. szinten keresek
          selectedItem = this.menu[l1].children.find((element) => element.menuId === item.menuId);
          if (selectedItem) {
            l2 = this.menu[l1].children.findIndex((element) => element.menuId === item.menuId);
            break;
          } else {
            for (l2 = 0; l2 < this.menu[l1].children.length; l2++) {
              // 3. szinten keresek
              selectedItem = this.menu[l1].children[l2].children.find((element) => element.menuId === item.menuId);
              if (selectedItem) {
                l3 = this.menu[l1].children[l2].children.findIndex((element) => element.menuId === item.menuId);
                break;
              } else {
                //
              }
            }
          }
          if (selectedItem) {
            break;
          }
        }
      }
      return { l1, l2, l3 };
    },
    async deleteMenuItem(item) {
      const resp = await this.findMenuItem(item);
      // console.log(resp);
      if (resp.l3 > -1) {
        this.menu[resp.l1].children[resp.l2].children.splice(resp.l3, 1);
      } else if (resp.l2 > -1) {
        this.menu[resp.l1].children.splice(resp.l2, 1);
      } else {
        this.menu.splice(resp.l1, 1);
      }
    },
    async showItem(item) {
      // console.log('clicked: ', item);
      let l1 = null;
      let l2 = null;
      let l3 = null;
      // 1. szinten keresek
      let selectedItem = this.menu.find((element) => element.menuId === item.menuId);
      if (selectedItem) {
        // console.log('%c1. szinten találat.', 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
        l1 = this.menu.findIndex((element) => element.menuId === item.menuId);
        // console.log(`%cl1 = ${l1}`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
      } else {
        // console.log('%c1. szinten nem találtunk semmit.', 'background-color: #e74c3c; color: #ffffff; font-size: 12px;');
        for (l1 = 0; l1 < this.menu.length; l1++) {
          // console.log('l1 = ', l1);
          // 2. szinten keresek
          selectedItem = this.menu[l1].children.find((element) => element.menuId === item.menuId);
          if (selectedItem) {
            // console.log('%c2. szinten találat.', 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
            l2 = this.menu[l1].children.findIndex((element) => element.menuId === item.menuId);
            // console.log(`%cl1 = ${l1}`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
            // console.log(`%cl2 = ${l2}`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
            break;
          } else {
            // console.log('%c2. szinten nem találtunk semmit.', 'background-color: #e74c3c; color: #ffffff; font-size: 12px;');
            for (l2 = 0; l2 < this.menu[l1].children.length; l2++) {
              // console.log('l2 = ', l2);
              // 3. szinten keresek
              selectedItem = this.menu[l1].children[l2].children.find((element) => element.menuId === item.menuId);
              if (selectedItem) {
                // console.log('%c3. szinten találat.', 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
                l3 = this.menu[l1].children[l2].children.findIndex((element) => element.menuId === item.menuId);
                // console.log(`%cl1 = ${l1}`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
                // console.log(`%cl2 = ${l2}`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
                // console.log(`%cl3 = ${l3}`, 'background-color: #27ae60; color: #ffffff; font-size: 12px;');
                break;
              } else {
                console.log('%c3. szinten nem találtunk semmit.', 'background-color: #e74c3c; color: #ffffff; font-size: 12px;');
              }
            }
          }
          if (selectedItem) {
            break;
          }
        }
      }
      // console.log('l1: ', l1);
      // console.log('l2: ', l2);
      // console.log('l3: ', l3);
      // console.log('find it: ', selectedItem);
    },
    openLARDialog() {
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError() {
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussCickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
      this.loadingAndResponseDialog.stepper = 1;
      this.$router.push({ name: 'menuBuilder-home' });
    },
    onErrorClickInLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
      this.loadingAndResponseDialog.stepper = 1;
    },
  },
};
</script>

<style scoped>

</style>
