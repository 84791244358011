<template>
  <draggable :class="nestedDragAreClass"
             tag="div"
             :list="items"
             :group="{ name: 'g1' }">
    <template v-for="(item, index) in items">
      <div :key="`dd${index}`" class="cursor-grab">
        <v-expansion-panels v-model="item.panel"
                            class="mb-3"
                            :key="index">
          <v-expansion-panel>
            <v-expansion-panel-header class="py-3 px-6">
              <v-list-item class="px-0">
                <v-list-item-action>
                  <v-icon>
                    mdi-arrow-all
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content class="py-0">
                  <v-list-item-title>
                    {{ item.label }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.url }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-header>
            <v-divider></v-divider>
            <v-expansion-panel-content>
              <v-row class="mx-n3">
                <v-col cols="12">
                  <v-text-field v-model="item.label"
                                :label="$t('labels.navigationLabel')"
                                dense
                                hide-details
                                filled>
                  </v-text-field>
                </v-col>
                <template v-if="item.type === customLinkType">
                  <v-text-field v-model="item.url"
                                :label="$t('labels.href')"
                                dense
                                hide-details
                                filled>
                  </v-text-field>
                </template>
                <v-col cols="12">
                  <v-row class="pa-3" style="border: 1px solid rgba(0,0,0,0.12);">
                    {{ `${$t('original')}: ${item.text}` }}
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="error"
                       @click="callDeleteMethod(item)">
                  {{ $t('button.delete') }}
                </v-btn>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-if="depth < 2" class="pl-4">
          <nested-draggable :items="item.children"
                            :depth="depth + 1"
                            v-on:parent-method="callParentMethod"
                            v-on:delete-method="callDeleteMethod"
                            :nested-drag-are-class="`nestedDragArea`"
                            :key="`nd${index}`"></nested-draggable>
        </div>
      </div>
    </template>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'NestedDraggable',
  components: {
    draggable,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    nestedDragAreClass: {
      type: String,
      default: 'firstDragArea',
    },
    depth: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      customLinkType: this.$navigationLinkCustom,
    };
  },
  methods: {
    async callParentMethod(item) {
      this.$emit('parent-method', item);
    },
    async callDeleteMethod(item) {
      this.$emit('delete-method', item);
    },
  },
};
</script>

<style scoped>

</style>
